<template>
  <div class="basic-data-list">
    <query-view
      ref="myQueryView"
      name="codeOrName"
      :value.sync="formModel.inNo"
      :placeholder="$t('lang.gles.orderManage.pleaseInputNo')"
      :more-visible.sync="moreQueryVisible"
      :query-list="moreQueryData"
      @baseSearch="onSearch(formModel)"
      @moreSearch="handleMoreQuery"
    />
    <el-card class="table-card">
      <div class="operator-wrapper">
        <!-- 列设置 -->
        <column-config :list.sync="columnConfigList" />
      </div>
      <m-table
        :table-item="tableItem"
        :table-data="tableData"
        :page-data="pageData"
        :extend-config="tableConfig"
        @pageChange="pageChange"
      >
        <template #clickSlot="{ row }">
          <span class="list-detail-item" @click="handleDetail(row)">
            {{ row.inNo }}
          </span>
        </template>
        <template #cancelSlot="{ row, item }">
          <m-confirm-btn
            :key="item.label"
            :label="item.label"
            :message="item.confirmMessage"
            type="text"
            :disabled="
              !!row.updownMaterialNo || ![10, 20].includes(row.statusCode)
            "
            :confirm-class="'mr10 ml0 f12 ' + (item.customClass || '')"
            @sureClick="() => _operateClick(item.event, row)"
          />
        </template>
      </m-table>
    </el-card>
    <!-- 更多查询 -->
    <!-- <more-query
      ref="myQueryView"
      :query-list="moreQueryData"
      :visible.sync="moreQueryVisible"
      @query="handleMoreQuery"
    /> -->
    <!-- 详情 -->
    <detail-dialog
      v-if="detailDialogVisible"
      :visible.sync="detailDialogVisible"
      :mode="mode"
      :options="detailOptions"
      :row-detail="row"
    />
  </div>
</template>
<script>
import ColumnConfig from '@/components/columnConfig'
import QueryView from '@/components/moreQuery/queryView.vue'
import DetailDialog from './detail.vue'
import MTable from '@/libs_sz/components/MTable/MTable'
import MConfirmBtn from '@/libs_sz/components/MConfirmBtn/MConfirmBtn'
import { getMoreQueryFormData, getSearchTableItem } from '../data'
import * as types from '@/store/mutationTypes'
import listMixins from '@/mixins/listMixins'
import orderManageMixins from '@/mixins/orderManageMixins'
import myTransform from '@/libs_sz/utils/transform'
import { mapState } from 'vuex'

export default {
  name: 'WarehousingOrder',
  components: {
    ColumnConfig,
    QueryView,
    MTable,
    DetailDialog,
    MConfirmBtn
  },
  mixins: [listMixins, orderManageMixins],
  data() {
    return {
      detailDialogVisible: false,
      tableData: [],
      formModel: {},
      tableConfig: {
        sortNum: true,
        checkBox: false,
        operateWidth: '80px',
        operate: [
          /* {
            event: 'cancelItem',
            label: this.$t('lang.gles.workflow.taskStatus.cancel'),
            slotName: 'cancelSlot',
            confirm: true,
            confirmMessage: this.$t('lang.gles.strategy.strategy.isSureCancel')
          } */
        ]
      },
      // 工厂
      factoryList: [],
      // 车间
      workshopList: [],
      // 仓库
      warehouseList: [],
      // 库区
      warehouseAreaList: [],
      // 库位
      factoryPositionList: [],
      // 区域
      areaList: [],
      // 产线
      productionLineList: [],
      // 设备
      deviceList: [],
      // 设备货位
      goodsPositionList: [],
      // 移动容器类型
      containerTypeList: [],
      // 周转容器类型
      turnoverContainerTypeList: [],
      // 物料编码
      materialCodeList: [],
      // 物料名称
      materialNameList: [],
      // 所有容器编码
      containerArchivesAllList: []
    }
  },
  computed: {
    ...mapState('base', [
      'storeInTypeList',
      'operatingModeList',
      'storeInStatusList',
      'taskStatusList',
      'robotWorkTypeList',
      'subTaskTypeList',
      'gradeStatusList'
    ]),
    moreQueryData() {
      return getMoreQueryFormData(this, this.formModel)
    },
    /**
     * 默认的表格列表项
     */
    defaultTableItem() {
      return getSearchTableItem(this)
    },
    detailOptions() {
      const options = {
        factoryList: this.factoryList,
        workshopList: this.workshopList,
        warehouseList: this.warehouseList,
        warehouseAreaList: this.warehouseAreaList,
        factoryPositionList: this.factoryPositionList,
        areaList: this.areaList,
        productionLineList: this.productionLineList,
        deviceList: this.deviceList,
        goodsPositionList: this.goodsPositionList,
        storeInTypeList: this.storeInTypeList,
        operatingModeList: this.operatingModeList,
        storeInStatusList: this.storeInStatusList,
        gradeStatusList: this.gradeStatusList,
        containerTypeList: this.containerTypeList,
        turnoverContainerTypeList: this.turnoverContainerTypeList,
        materialCodeList: this.materialCodeList,
        materialNameList: this.materialNameList,
        containerArchivesAllList: this.containerArchivesAllList,
        taskStatusList: this.taskStatusList,
        robotWorkTypeList: this.robotWorkTypeList,
        subTaskTypeList: this.subTaskTypeList
      }
      return options
    },
    /**
     * 渲染的表格列表项  列设置
     */
    tableItem() {
      let list = this.defaultTableItem
      if (this?.columnConfigList?.length) {
        list = this.defaultTableItem.filter(
          (item, i) =>
            item.name === this.columnConfigList[i]?.name &&
            this.columnConfigList[i]?.isShow
        )
      }
      return list
    }
  },
  async created() {
    // this.createStoreIn()
    this.doSearch(this.formModel)
    this.initSelectList()
  },
  methods: {
    /**
     * 初始化下拉选项
     */
    initSelectList() {
      this.getBaseData('getFactoryDictList', 'factoryList', 'factoryCode')
      this.getBaseData('getWarehouseDictList', 'warehouseList', 'warehouseCode')
      this.getBaseData('getWorkshopDictList', 'workshopList', 'workshopCode')
      this.getBaseData('getAreaDictList', 'areaList', 'areaCode')
      this.getBaseData(
        'getProductionLineDictList',
        'productionLineList',
        'productionLineCode'
      )
      this.getBaseData(
        'getWarehouseAreaDictList',
        'warehouseAreaList',
        'warehouseAreaCode'
      )
      this.getBaseData(
        'getFactoryPositionDictList',
        'factoryPositionList',
        'factoryPositionCode'
      )
      this.getBaseData('getDeviceDictList', 'deviceList', 'deviceCode')
      this.getBaseData(
        'getGoodsPositionListByType',
        'goodsPositionList',
        'goodsPositionCode'
      )
      this.getContainerTypeList([], 'containerTypeList')
      this.getContainerTypeList(
        ['containerPatternBarrel', 'containerPatternCrate'],
        'turnoverContainerTypeList'
      )
      this.getMaterialCodeList()
      this.getContainerArchivesAllList()

      if (!this.storeInStatusList?.length) {
        this.$store.dispatch('base/getStoreInStatusList')
      }
      if (!this.storeInTypeList?.length) {
        this.$store.dispatch('base/getStoreInTypeList')
      }
      if (!this.operatingModeList?.length) {
        this.$store.dispatch('base/getOperatingModeList')
      }
      if (!this.taskStatusList?.length) {
        this.$store.dispatch('base/getTaskStatusList')
      }
      if (!this.robotWorkTypeList?.length) {
        this.$store.dispatch('base/getRobotWorkTypeList')
      }
      if (!this.subTaskTypeList?.length) {
        this.$store.dispatch('base/getSubTaskTypeList')
      }
      if (!this.gradeStatusList?.length) {
        this.$store.dispatch('base/getGradeStatusList')
      }
    },
    // 获取容器类型
    async getContainerTypeList(queryData, array) {
      const params = {
        containerTypePatternList: queryData
      }
      const { code, data } = await this.$httpService(
        this.$apiStore.materialManage('getContainerType'),
        params
      )
      if (code) return
      this[array] = myTransform.arrToOptions(
        data,
        'containerTypeName',
        'containerTypeId'
      )
    },
    // 获取物料编码的下拉数据
    async getMaterialCodeList() {
      const { code, data } = await this.$httpService(
        this.$apiStore.materialManage('queryMaterialOption')
      )
      if (code) return
      this.materialCodeList = data.map((item) => {
        return { label: item.materialCode, value: item.id }
      })
      this.materialNameList = data.map((item) => {
        return { label: item.materialName, value: item.id }
      })
    },
    // 所有容器编码
    async getContainerArchivesAllList() {
      const { code, data } = await this.$httpService(
        this.$apiStore.orderManage('getContainerArchivesAllList')
      )
      if (code) return
      this.containerArchivesAllList =
        data &&
        data.map((item) => {
          return { label: item.containerArchivesCode, value: item.id }
        })
    },
    /**
     * 新增
     */
    updateCom({ row }) {
      this.$emit('updateCom', {
        currentCom: 'WarehousingOrderDetail',
        mode: 'add',
        row
      })
    },
    /**
     * 查看详情
     */
    handleDetail(row) {
      this.detailDialogVisible = true
      this.row = row
      this.mode = 'detail'
    },
    /**
     * 更多查询
     */
    handleMoreQuery(event) {
      this.formModel = { ...event }
      if (event.createTime && event.createTime.length) {
        event.createTimeStart = event.createTime[0]
        event.createTimeEnd = event.createTime[1]
      }
      if (event.updateTime && event.updateTime.length) {
        event.updateTimeStart = event.updateTime[0]
        event.updateTimeEnd = event.updateTime[1]
      }
      if (event.completedTime && event.completedTime.length) {
        event.completedTimeStart = event.completedTime[0]
        event.completedTimeEnd = event.completedTime[1]
      }
      const obj = {
        ...event
      }
      delete obj.createTime
      delete obj.updateTime
      delete obj.completedTime
      this.doSearch({ ...obj }, 'listStoreInByPage')
      // this.$refs.myQueryView.reset()
    },
    onSearch() {
      this.pageData.currentPage = 1
      this.doSearch(this.formModel)
    },
    async createStoreIn() {
      const params = {
        factoryId: 1,
        workshopId: 1,
        warehouseId: 1,
        areaId: 1,
        productionLineId: 1,
        warehouseAreaId: 1,
        factoryPositionId: 1,
        deviceId: 1,
        goodsPositionId: 1,
        receiptType: 101,
        operatingMode: 1,
        containerSum: 1,
        materialSum: 2,
        turnoverContainerSum: 2,
        statusCode: 10,
        remark: 'create store in',
        storeInDetailList: [
          {
            containerTypeId: 1,
            containerId: 1,
            containerNum: 1,
            assignInWarehouseAreaId: 1,
            assignInWarehousePositionId: 1,
            assignInGoodsPositionId: 1,
            goodsPositionId: 1,
            turnoverContainerTypeId: 2,
            turnoverContainerId: 1,
            turnoverContainerNum: 1,
            materialId: 1,
            materialUnit: '个',
            materialNum: 1,
            materialCountNum: 1,
            materialCountUnit: '个',
            materialDirection: 'N',
            materialBatchNo: '123456',
            manufactureDate: '2022-01-21 12:25:43',
            expirationDate: '2022-01-21 12:25:43',
            materialGrade: 10,
            batchProp1: '批属性1',
            batchProp2: '批属性2',
            batchProp3: '批属性3',
            batchProp4: '批属性4',
            batchProp5: '批属性5',
            batchProp6: '批属性6',
            batchProp7: '批属性7',
            batchProp8: '批属性8',
            batchProp9: '批属性9',
            batchProp10: '批属性10'
          },
          {
            containerTypeId: 1,
            assignInWarehouseAreaId: 1,
            assignInWarehousePositionId: 1,
            assignInGoodsPositionId: 1,
            goodsPositionId: 1,
            turnoverContainerTypeId: 2,
            turnoverContainerId: 1,
            turnoverContainerNum: 1,
            materialId: 1,
            materialUnit: '个',
            materialNum: 1,
            materialCountNum: 1,
            materialCountUnit: '个',
            materialDirection: 'N',
            materialBatchNo: '123456',
            manufactureDate: '2022-01-21 12:25:43',
            expirationDate: '2022-01-21 12:25:43',
            materialGrade: 20,
            batchProp1: '批属性1',
            batchProp2: '批属性2',
            batchProp3: '批属性3',
            batchProp4: '批属性4',
            batchProp5: '批属性5',
            batchProp6: '批属性6',
            batchProp7: '批属性7',
            batchProp8: '批属性8',
            batchProp9: '批属性9',
            batchProp10: '批属性10'
          }
        ]
      }
      const { data } = await this.$httpService(
        this.$apiStore.orderManage('createStoreIn'),
        params
      )
      console.log(data)
    },
    /**
     * 接口查询
     */
    async doSearch(params = {}, api = 'listStoreInByPage') {
      try {
        const { data, code } = await this.$httpService(
          this.$apiStore.orderManage(api, this.pageData),
          params
        )
        this.$store.commit(types.IS_LOADING, false)
        if (code) return
        const {
          recordList = [],
          currentPage,
          pageSize,
          totalRecordCount
        } = data || {}
        this.pageData = { currentPage, pageSize, recordCount: totalRecordCount }
        this.tableData = recordList || []
      } catch (error) {
        this.$store.commit(types.IS_LOADING, false)
      }
    },
    /**
     * 取消
     */
    cancelItem({ row }) {
      // console.log(row)
      const params = {
        receiptNo: row.inNo,
        receiptType: row.receiptType
      }
      this.$httpService(
        this.$apiStore.monitorManage('postCancel'),
        params
      ).then(({ code }) => {
        if (code) return
        this.$message.success(this.$t('lang.gles.common.handleSuccess'))
        this.doSearch(this.formModel)
      })
    },
    _operateClick(event, row) {
      this[event]({ row })
    }
  }
}
</script>
