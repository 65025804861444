<template>
  <div class="log-info">
    <m-table
      border
      :table-item="tableItem"
      :table-data="tableData"
      :extend-config="tableConfig"
      :page-data="pageData"
    />
  </div>
</template>
<script>
import { getLogTableItems } from '../data'
import addMixins from '@/mixins/addMixins'

export default {
  mixins: [addMixins],
  props: {
    mode: {
      type: String,
      default: 'add'
    },
    rowDetail: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      activeName: 'info',
      currentCom: '',
      tableData: [],
      tableConfig: {
        sortNum: true,
        checkBox: false
      },
      pageData: null
    }
  },
  computed: {
    tableItem() {
      return getLogTableItems(this, this.options)
    }
  },
  created() {
    this.listLogDetail()
  },
  methods: {
    async listLogDetail() {
      const params = {
        businessNo: this.rowDetail.inNo
      }
      const { data } = await this.$httpService(
        this.$apiStore.orderManage('listLogDetail'),
        params
      )
      this.tableData = data || []
    },
    /**
     * 取消
     */
    onCancel() {
      this.$emit('updateCom', {
        currentCom: 'WarehousingOrderList'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
